import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import DevOpsComponent from "../components/devOps/devOps"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { FAQSchemaGenerate } from "../schemas-org"

const query = graphql`
  query DevOpsPageEN {
    datoCmsDevopsPage(locale: { eq: "en" }) {
      seo {
        title
        description
        twitterCard
        image {
          url
        }
      }
      heading
      description
      headerBackground {
      url
     }
      sectionWithQuote {
        title
        text
        comment
        author
        authorPosition
      }
      customerCasesHeading
      customerCasesContent {
        image {
          alt
          gatsbyImageData(height: 260, layout: FIXED)
        }
        description
        link
        title
        id
        imageBackgroundColor {
          hex
        }
      }
      devOpsBenefits {
        icon {
          url
          alt
        }
        title
        content
      }
      benefitsHeading
      benefitsQuoteContent
      benefitsQuoteAuthor
      benefitsQuotePosition
      techStackImage {
        gatsbyImageData
        alt
      }
      offerTitle
      offerSubtitle
      offerCards{
        icon{
          url
        }
        title
        content
      }
      intriguedHeading
      intriguedText
      intriguedItems {
        icon {
          url
          alt
        }
        title
        text
      }
      techStackHeading
      techStackTypes {
        title
        technologies {
          title
        }
      }
      contactImage {
        gatsbyImageData
        alt
      }
      buttonCta
      callToAction
      showFaq
      faq {
        title
        faqItems {
          question
          answer
        }
        faqMoreQuestions
        faqConsultation
      }
      customSoftwareSolutionsHeading
    }
    allDatoCmsProject(
      filter: { showOnDevops: { eq: true }, locale: { eq: "en" } }
    ) {
      nodes {
        title
        slug
        mainImage {
          alt
          gatsbyImageData(height: 260, layout: FIXED)
          url
        }
        projectBackground {
          hex
        }
        hashtags {
          hashtag
        }
      }
    }
  }
`

const DevOps = props => {
  const data = useStaticQuery(query)
  const lang = props.pageContext.langKey
  const { seo } = data.datoCmsDevopsPage
  const { faq, showFaq } = data.datoCmsDevopsPage

  return (
    <Layout whiteNav isHome lang={lang} location={props.location} showContact={false}>
      <Seo
        title={seo.title ?? ""}
        description={seo.description ?? ""}
        meta={seo.description ?? ""}
        image={seo && seo.image && seo.image.url}
        schemaMarkup={showFaq && FAQSchemaGenerate(faq[0].faqItems)}
        slug='devops-services'
      />
      <DevOpsComponent lang={lang} data={data} />
    </Layout>
  )
}

export default DevOps
