import React, { useEffect, useRef, useState } from "react"
import { QuoteIconTop, QuoteIconBottom } from "../../Icons"

export const DevopsBenefitsSection = ({ items, quote, title }) => {
  const activePhasesBlock = true;
  return (
    <div className="dev-ops-benefits__container">
      <div className="container">
        <h2 className="dev-ops-benefits__title">{title}</h2>
        <div className="dev-ops-benefits__item">
          <div className="dev-ops-benefits__item__phases-block">
            <div className="dev-ops-benefits__item__phases__dashed-border">
              <div
                className={`${
                  activePhasesBlock
                    ? `dev-ops-benefits__item__phases__dashed-border__circles_hidden`
                    : null
                } dev-ops-benefits__item__phases__dashed-border__circles`}
              >
                {items.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="dev-ops-benefits__item__phases__dashed-border__circle"
                    />
                  )
                })}
              </div>
            </div>
            <div
              className={`dev-ops-benefits__item__phases ${
                !activePhasesBlock ? "" : "expanded"
              }`}
            >
              {items.map((step, phaseIndex) => (
                <Step
                  key={phaseIndex + step.title}
                  step={step}
                  phaseIndex={phaseIndex}
                  activePhasesBlock={activePhasesBlock}
                  quote={quote}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const Step = ({ step, phaseIndex, activePhasesBlock, quote }) => {
  const elementRef = useRef(null)
  const [isActive, setIsActive] = useState(true)

  useEffect(() => {
    const windowHeight = window.innerHeight

    const calculateDistance = () => {
      phaseIndex !== 0 &&
        setIsActive(
          windowHeight - elementRef.current.getBoundingClientRect().top > 150
            ? true
            : false
        )
    }

    if (activePhasesBlock) {
      phaseIndex === 0 ? setIsActive(true) : setIsActive(false)
      window.addEventListener("scroll", calculateDistance)
    }

    return () => {
      window.removeEventListener("scroll", calculateDistance)
    }
  }, [activePhasesBlock, phaseIndex])

  return (
    <div
      ref={elementRef}
      order={phaseIndex}
      className={`dev-ops-benefits__item__phase ${isActive ? "active" : ""}
        ${!activePhasesBlock && phaseIndex > 0 ? `hidden` : `visible`}`}
    >
      <img
        src={step.icon.url}
        alt={step.icon.alt}
        className="dev-ops-benefits__item__icon"
      />
      <div className="dev-ops-benefits__item__content">
        <h3 className="dev-ops-benefits__item__phase__title">{step.title}</h3>
        <div
          className="dev-ops-benefits__item__phase__text"
          dangerouslySetInnerHTML={{ __html: step.content }}
        />
      </div>
      {phaseIndex === 2 && (
        <div className="quote-section__comment">
          <div className="quote-section__comment__text-block">
            <QuoteIconTop />
            <div className="quote-section__comment__text">
              {quote.benefitsQuoteContent}
            </div>
            <QuoteIconBottom />
          </div>
          <div className="quote-section__comment__content">
            <h4 className="quote-section__comment__author">
              {quote.benefitsQuoteAuthor}
            </h4>
            <p className="quote-section__comment__position">
              {quote.benefitsQuotePosition}
            </p>
          </div>
          <div className="quote-section__comment__triangle" />
        </div>
      )}
    </div>
  )
}
