import React from "react"
import { ServicesHero } from "../ServicePageSections/ServiceHero"
import { QuoteSection } from "../ServicePageSections/QuoteSection"
import { CtaSection } from "../ServicePageSections/CtaSection"
import { FaqSection } from "../ServicePageSections/FaqSection"
import { SmartSolutionsSection } from "../ServicePageSections/SmartSolutions"
import { SolutionsWeBuildSection } from "../ServicePageSections/SolutionsWeBuildSection"
import { DevopsBenefitsSection } from "./sections/benefitsDevOps"
import { OfferSection } from "./sections/offerDevOps"
import { TechStackSection } from "../ServicePageSections/TechStack"
import { IntriguedSection } from "./sections/intriguedDevOps"

const DevOpsComponent = ({ lang, data }) => {
  const {
    heading,
    description,
    headerBackground,
    sectionWithQuote,
    customerCasesHeading,
    customerCasesContent,
    benefitsHeading,
    devOpsBenefits,
    benefitsQuoteContent,
    benefitsQuoteAuthor,
    benefitsQuotePosition,
    offerTitle,
    offerSubtitle,
    offerCards,
    intriguedHeading,
    intriguedText,
    intriguedItems,
    techStackImage,
    techStackHeading,
    techStackTypes,
    contactImage,
    buttonCta,
    callToAction,
    showFaq,
    faq,
    customSoftwareSolutionsHeading,
  } = data.datoCmsDevopsPage

  const benefitsQuote = {
    benefitsQuoteContent,
    benefitsQuoteAuthor,
    benefitsQuotePosition,
  }

  const projects = data.allDatoCmsProject.nodes
  return (
    <div className="dev-ops__page">
      <ServicesHero
        title={heading}
        description={description}
        imgSrc={headerBackground}
      />
      <QuoteSection content={sectionWithQuote[0]} />
      <DevopsBenefitsSection
        title={benefitsHeading}
        items={devOpsBenefits}
        quote={benefitsQuote}
      />
      <SmartSolutionsSection
        lang={lang}
        title={customerCasesHeading}
        cards={customerCasesContent}
      />
      <OfferSection
        title={offerTitle}
        subtitle={offerSubtitle}
        cards={offerCards}
      />
      <IntriguedSection
        title={intriguedHeading}
        subtitle={intriguedText}
        items={intriguedItems}
        lang={lang}
      />
      <TechStackSection
        image={techStackImage}
        title={techStackHeading}
        items={techStackTypes}
      />
      <CtaSection
        lang={lang}
        image={contactImage}
        buttonCta={buttonCta}
        callToAction={callToAction}
      />
      {showFaq && (
        <FaqSection
          items={faq[0].faqItems}
          title={faq[0].title}
          secondTitle={faq[0].faqMoreQuestions}
          secondSubtitle={faq[0].faqConsultation}
          lang={lang}
        />
      )}
      <SolutionsWeBuildSection
        showFaq={showFaq}
        title={customSoftwareSolutionsHeading}
        projects={projects}
        lang={lang}
      />
    </div>
  )
}

export default DevOpsComponent
