import React from "react"
import Link from "../../Link"

export const IntriguedSection = ({ title, subtitle, items, lang }) => {
  return (
    <section className="intrigued__container">
      <div className="container">
        <div className="intrigued">
          <h2 className="digital-solution__content__title intrigued__title">
            {title}
          </h2>
          <div
            className="intrigued__subtitle"
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
          <div className="intrigued__items__container">
            {items.map(item => (
              <div key={item.title} className="intrigued__item">
                <img
                  src={item.icon.url}
                  alt={item.icon.alt}
                  className="intrigued__item__image"
                />
                <h3 className="intrigued__item__title">{item.title}</h3>
                <p className="intrigued__item__text">{item.text}</p>
              </div>
            ))}
          </div>
          <div className="btn__primary--animation intrigued__button">
            <Link
              className="btn btn__primary"
              to={"/contact/"}
              lang={lang}
            >
              {lang === "en" ? "Contact us" : "Sprechen Sie uns an"}
            </Link>
          </div>
        </div>
        <div className="intrigued__bg__gradient" />
        <div className="intrigued__particle" />
        <div className="intrigued__particle__gradient" />
        <div className="intrigued__particles">
          <div className="intrigued__particles__item-1" />
          <div className="intrigued__particles__item-2" />
        </div>
      </div>
    </section>
  )
}
