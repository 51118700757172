import React from "react"

export const OfferSection = ({ title, subtitle, cards }) => {
  return (
    <section className="dev-ops-offer__container">
      <div className="container">
        <div className="dev-ops-offer">
          <h2 className="dev-ops-offer__title">{title}</h2>
          <h3 className="dev-ops-offer__subtitle">{subtitle}</h3>
          <div className="customised-software-solutions__items">
            {cards.map((item, index) => (
              <div
                key={title + index}
                className="customised-software-solutions__item"
              >
                <img
                  src={item.icon.url}
                  className="customised-software-solutions__item__image"
                  alt="Devops offer card icon"
                />
                <h3
                  className="customised-software-solutions__item__title"
                  dangerouslySetInnerHTML={{ __html: item.title }}
                />
                <p className="customised-software-solutions__item__text">
                  {item.content}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
